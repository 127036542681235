<template>
    <div>
        <div v-if="isSubmitting">
            <Loader text="Skapar" />
            <p class="text-center" style="font-style:italic;">Observera att kopieringen kan ta några minuter</p>
        </div>
        <b-list-group v-for="(customer, index) in customers"
                      :key="customer.id"
                      :class="{'mt-3': index > 0}"
                      v-if="!isSubmitting">
            <b-list-group-item role="button"
                               :disabled="true">
                <font-awesome-icon icon="address-book"></font-awesome-icon> {{ customer.name }} - {{ customer.customerNo }}
            </b-list-group-item>
            <b-list-group-item v-for="building in customer.buildings"
                               :key="building.id"
                               class="ml-4"
                               @click="copyBuilding(building.id, building.name)"
                               role="button">
                <font-awesome-icon icon="building"></font-awesome-icon> {{ building.name }}
            </b-list-group-item>
        </b-list-group>
    </div>
</template>
<script>
    import { get, post } from '@/helpers/api';
    import { mapState } from 'vuex';
    import Loader from '@/components/Loader';

    export default {
        name: 'CopyBuilding',
        components: {
            Loader
        },
        data() {
            return {
                isSubmitting: false,
                selectedBuilding: null,
                customers: [],
            };
        },
        computed: {
            ...mapState({
                selectedCustomerId: (state) => state.user.selectedCustomer.id,
                selectedCustomerNo: (state) => state.user.selectedCustomer.customerNo,
                selectedCustomerName: (state) => state.user.selectedCustomer.name
            })
        },
        async mounted() {
            this.getData();
        },
        methods: {
            async copyBuilding(id, buildingName) {
                this.$bvModal
                    .msgBoxConfirm(
                        `Är du säker på att du vill skapa fastigheten utifrån mallen '${buildingName}'?`,
                        {
                            title: '',
                            size: 'sm',
                            cancelVariant: 'danger',
                            cancelTitle: 'Nej',
                            okTitle: 'Ja',
                            okVariant: 'primary',
                            footerClass: 'p-2 justify-content-between',
                            hideHeaderClose: false,
                            centered: true
                        }
                    )
                    .then(async (confirmed) => {
                        if (confirmed) {
                            this.isSubmitting = true;

                            try {
                                await post('Copy', `CopyBuildingTemplateToCustomer/${id}?customerId=${this.selectedCustomerId}`)
                                    .then((x) => {
                                        this.$router.push({ name: 'manage-building', params: { id: x.data, tab: 'view' } });
                                    });
                            } catch (ex) {
                                this.reset();
                                this.isSubmitting = false;
                            }
                        }
                    })
                    .catch(() => {
                        this.isSubmitting = true;
                    });
            },
            async getData() {
                await get('Copy', 'GetAllTemplate')
                    .then(x => this.customers = x.data);
            },
            close() {
                this.$emit('close');
            },
        }
    };
</script>

<style>
    .list-group-item:hover,
    .list-group-item:active,
    .list-group-item:focus {
        background-color: #efefef;
    }
</style>
